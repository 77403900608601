import { Injectable } from '@angular/core';

import { AuthService } from '@core/services/auth.service';
import { UserInformationService } from '@core/services/user-information.service';

/**
 * Prüft, ob der Benutzer zum Aufruf gewisser Seiten
 * berechtigt ist.
 */
@Injectable()
export class AuthGuard  {

    constructor(private authService: AuthService, private userService: UserInformationService) {
    }

    /**
     * Überprüft die Berechtigung eines Benutzers eine gewisse Route
     * zu aktivieren.
     * @returns Kann die Route besucht werden
     */
    canActivate(): boolean {

        if (this.authService.isAuthenticated() && this.userService.hasUserInfo()) {
            return true;
        }

        return false;
    }
}
