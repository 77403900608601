<!-- Gemeindewappen -->
<div *ngIf="userService.mandantInfo$ | async as mandant">
    <div
        [ngClass]="{
            'nav-branding-sidenavSmall': navigationService.sidenavSmall,
            'nav-branding': !navigationService.sidenavSmall
        }"
    >
        <k5-mandant-darstellung
            class="w-full"
            [bildUrl]="mandant?.bildUriPath | mandantServiceUrl"
            [darstellungData]="mandant?.darstellung"
            [displaySmall]="navigationService.sidenavSmall"
        >
        </k5-mandant-darstellung>
    </div>
</div>
<!-- Fixierte Links oben -->
<div *ngIf="isAuthenticated()">
    <div *ngFor="let navItem of navItemsStart$ | async">
        <div class="line-separator large-line-separator"></div>
        <k5-nav-link
            [label]="navItem.label"
            [link]="navItem.routerLink"
            [children]="navItem.hasChildren"
            [icon]="navItem.icon"
            [navLevel]="navItem.navLevel"
            [exactUrlActiveMatching]="navItem.exactUrlActiveMatching"
            [collapsed]="navigationService.sidenavSmall$ | async"
            [attr.data-cy]="navItem.cypressId"
        >
        </k5-nav-link>
    </div>
</div>

<!--K5Next Apps-->
<div class="nav-items-container" *ngIf="isAuthenticated(); else placeholder" (scroll)="scrolled($event)">
    <div *ngFor="let navItem of navItemsCenter$ | async">
        <ng-container *ngIf="hasPermissions(navItem.permissions)">
            <div *ngIf="isNavItemVisible(navItem)" class="line-separator large-line-separator"></div>
            <k5-nav-link
                [label]="navItem.label"
                [link]="navItem.routerLink"
                [url]="navItem.url"
                [children]="navItem.hasChildren"
                [childrenVisible]="navItem.childrenVisible"
                [visible]="isNavItemVisible(navItem)"
                [icon]="navItem.icon"
                [navLevel]="navItem.navLevel"
                [exactUrlActiveMatching]="navItem.exactUrlActiveMatching"
                [collapsed]="navigationService.sidenavSmall$ | async"
                [tooltipOffsetTop]="scrollTop"
                [attr.data-cy]="navItem.cypressId"
                (navlinkClick)="checkUnsavedChanges()"
            >
            </k5-nav-link>
            <div *ngFor="let nav2ndLevel of navItem.children">
                <div *ngIf="hasPermissions(nav2ndLevel.permissions) && navItem.childrenVisible">
                    <div class="line-separator small-line-separator"></div>
                    <k5-nav-link
                        [label]="nav2ndLevel.label"
                        [link]="nav2ndLevel.routerLink"
                        [children]="nav2ndLevel.hasChildren"
                        [childrenVisible]="nav2ndLevel.childrenVisible"
                        [icon]="nav2ndLevel.icon"
                        [navLevel]="nav2ndLevel.navLevel"
                        [exactUrlActiveMatching]="nav2ndLevel.exactUrlActiveMatching"
                        [collapsed]="navigationService.sidenavSmall$ | async"
                        [tooltipOffsetTop]="scrollTop"
                        [attr.data-cy]="nav2ndLevel.cypressId"
                    >
                    </k5-nav-link>
                </div>
                <div *ngFor="let nav3rdLevel of nav2ndLevel.children">
                    <div *ngIf="hasPermissions(nav3rdLevel.permissions) && nav2ndLevel.childrenVisible">
                        <div class="line-separator small-line-separator"></div>
                        <k5-nav-link
                            [label]="nav3rdLevel.label"
                            [link]="nav3rdLevel.routerLink"
                            [children]="nav3rdLevel.hasChildren"
                            [icon]="nav3rdLevel.icon"
                            [navLevel]="nav3rdLevel.navLevel"
                            [exactUrlActiveMatching]="nav3rdLevel.exactUrlActiveMatching"
                            [collapsed]="navigationService.sidenavSmall$ | async"
                            [tooltipOffsetTop]="scrollTop"
                            [attr.data-cy]="nav3rdLevel.cypressId"
                        >
                        </k5-nav-link>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="line-separator large-line-separator"></div>
</div>

<ng-template #placeholder>
    <h1>Nicht authentifiziert</h1>
</ng-template>

<!-- Fixierte Links unten -->
<div class="footer" *ngIf="isAuthenticated()">
    <div class="line-separator large-line-separator"></div>
    <k5-nav-link
        label="Hilfe"
        (navlinkClick)="hilfeNavLinkClicked()"
        [children]="false"
        [link]="null"
        icon="fa-duotone fa-location-question"
        [navLevel]="1"
        [collapsed]="navigationService.sidenavSmall$ | async"
        [attr.data-cy]="'nav-content-k5-nav-link-k5next-hilfe'"
    >
    </k5-nav-link>
    <div class="line-separator large-line-separator"></div>
    <k5-nav-link
        [label]="userName"
        [link]="routerConstants.USERPROFILE"
        [children]="false"
        icon="user-circle"
        [navLevel]="1"
        [exactUrlActiveMatching]="false"
        [collapsed]="navigationService.sidenavSmall$ | async"
        [actionEnabled]="true"
        actionIcon="sign-out"
        [picture]="getPicture()"
        actionTooltip="Abmelden"
        (actionClick)="logout()"
        [attr.data-cy]="'nav-content-k5-nav-link-benutzerprofil'"
    >
    </k5-nav-link>
</div>
