import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InitializationComponent } from '@core/components/initialization/initialization.component';
import { UiConstants } from '@core/constants/ui-constants';
import { PathConstants } from '@core/constants/url-constants';
import { AuthGuard } from '@core/guards/auth.guard';
import { userRightsGuard } from '@core/guards/user-rights.guard';

const routes: Routes = [
    {
        path: PathConstants.MANDANTEN,
        loadChildren: () => import('./administration/mandanten/mandanten.module').then((m) => m.MandantenModule),
        canActivate: [
            AuthGuard,
            userRightsGuard([UiConstants.MANDANT_MODIFY, UiConstants.MANDANT_DELETE, UiConstants.MANDANT_CREATE])
        ],
        data: { title: UiConstants.MANDANTEN_TITLE }
    },
    {
        path: PathConstants.BENUTZERVERWALTUNG,
        loadChildren: () =>
            import('./administration/benutzerverwaltung/benutzerverwaltung.module').then(
                (m) => m.BenutzerverwaltungModule
            ),
        canActivate: [
            AuthGuard,
            userRightsGuard([
                UiConstants.BENUTZER_MODIFY,
                UiConstants.ADMINISTRATOR_MODIFY,
                UiConstants.BENUTZER_DELETE
            ])
        ],
        data: { title: UiConstants.BENUTZER_TITLE }
    },
    {
        path: PathConstants.ROLLEN,
        loadChildren: () => import('./administration/rollen/rollen.module').then((m) => m.RollenModule),
        canActivate: [AuthGuard, userRightsGuard([UiConstants.ROLLE_MODIFY, UiConstants.ROLLE_DELETE])],
        data: { title: UiConstants.ROLLEN_TITLE }
    },
    {
        path: PathConstants.MODULE_MANAGEMENT,
        loadChildren: () =>
            import('./administration/module-management/module-management.module').then((m) => m.ModuleManagementModule),
        canActivate: [AuthGuard, userRightsGuard([UiConstants.MODUL_READ, UiConstants.MODUL_MODIFY])],
        data: { title: UiConstants.MODULE_TITLE }
    },
    {
        path: PathConstants.HOME,
        loadChildren: () => import('./administration/home/home.module').then((m) => m.HomeModule),
        canActivate: [AuthGuard],
        data: { title: UiConstants.STANDARD_TITLE }
    },
    {
        path: PathConstants.ADMINISTRATION,
        loadChildren: () =>
            import('./administration/administration/administration.module').then((m) => m.AdministrationModule),
        canActivate: [AuthGuard, userRightsGuard([UiConstants.PERSON_GENERATE, UiConstants.WAHL_GENERATE])],
        data: { title: UiConstants.ADMINISTRATION_TITLE }
    },
    {
        path: PathConstants.WAHLEN,
        loadChildren: () => import('./administration/wahlen/wahlen.module').then((m) => m.WahlenModule),
        canActivate: [AuthGuard, userRightsGuard([UiConstants.WAHL_ADMINISTRATION, UiConstants.WAHL_MONITORING])],
        data: { title: UiConstants.WAHLEN_TITLE }
    },
    {
        path: PathConstants.USERPROFILE,
        loadChildren: () => import('./administration/user/user.module').then((m) => m.UserModule),
        canActivate: [AuthGuard],
        data: { title: UiConstants.USER_LABEL }
    },
    {
        path: PathConstants.STATUS,
        loadChildren: () => import('./administration/status/status.module').then((m) => m.StatusModule),
        canActivate: [AuthGuard, userRightsGuard([UiConstants.STATUS_VIEW])],
        data: { title: UiConstants.STATUS_TITLE }
    },
    {
        path: PathConstants.INITIALIZATION,
        component: InitializationComponent
    },
    // Redirect URI
    { path: '**', redirectTo: PathConstants.INITIALIZATION, pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
