import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    notificationAmount = 1;

    constructor() { }
}
