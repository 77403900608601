import { Component } from '@angular/core';

@Component({
    selector: 'k5-redirect',
    templateUrl: './initialization.component.html',
    styleUrls: ['./initialization.component.scss']
})
export class InitializationComponent {

}
