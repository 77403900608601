<!-- Visible Attribut referenziert auf das Feature Flag #6930 -->
<div
    *ngIf="visible"
    class="link"
    [ngClass]="{
        'display-1st-level': navLevel === 1,
        'display-2nd-level': navLevel === 2,
        'display-3rd-level': navLevel >= 3
    }"
>
    <a
        id="standardLink"
        #linkElement
        matRipple
        class="main-link cursor-pointer select-none"
        [routerLink]="link"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: exactUrlActiveMatching }"
        (click)="linkClicked($event)"
    >
        <!-- Indikator für die Selektion -->
        <div class="selection-line"></div>

        <!-- Link Icon -->
        <div
            class="icon"
            [matBadge]="badgeCount"
            matBadgeSize="small"
            [matBadgeHidden]="badgeCount > 0 ? false : true"
            matBadgeOverlap="true"
            matBadgeColor="warn"
        >
            <i
                class="fa-duotone fa-{{ icon }} fa-fw"
                *ngIf="!picture"
                [ngClass]="{
                    'fa-2x': navLevel === 1,
                    'fa-md': navLevel === 2,
                    'fa-sm': navLevel >= 3,
                    'icon-navChild': navLevel >= 2
                }"
                aria-hidden="true"
            ></i>
            <img [src]="picture" *ngIf="picture" alt="picture" class="profile-picture" />
        </div>

        <!-- Link Bezeichnung -->
        <div
            class="label"
            *ngIf="!collapsed"
            [ngClass]="{ 'pl-md': navLevel === 1, 'pl-lg': navLevel === 2, 'pl-3rd-level': navLevel >= 3 }"
        >
            {{ label }}
        </div>

        <!-- Indikator für Sublinks und Schnellaktion -->
        <div class="indicator-content" *ngIf="!collapsed && children">
            <i
                *ngIf="children && !childrenVisible"
                class="fa-solid fa-chevron-right fa-fw fa-lg"
                aria-hidden="true"
            ></i>
            <i *ngIf="children && childrenVisible" class="fa-solid fa-chevron-down fa-fw fa-lg" aria-hidden="true"></i>
        </div>
    </a>

    <!-- Zusätzliche Aktion -->
    <div
        class="action main-action"
        matRipple
        *ngIf="actionEnabled && !collapsed"
        [matTooltip]="actionTooltip"
        [ngClass]="{
            'display-1st-level': navLevel === 1,
            'display-2nd-level': navLevel === 2,
            'display-3rd-level': navLevel >= 3
        }"
        (click)="actionClicked($event)"
    >
        <i class="fa-duotone fa-{{ actionIcon }} fa-fw fa-lg" aria-hidden="true"></i>
    </div>

    <!-- Tooltip bei hover in der schmalen Darstellung -->
    <div #tooltipElement class="tooltip">
        <a
            *ngIf="collapsed"
            matRipple
            class="link cursor-pointer select-none"
            [routerLink]="link"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: exactUrlActiveMatching }"
            [ngClass]="{
                'display-1st-level': navLevel === 1,
                'display-2nd-level': navLevel === 2,
                'display-3rd-level': navLevel >= 3
            }"
            (click)="linkClicked($event)"
        >
            <div class="selection-line"></div>

            <div
                class="icon"
                [matBadge]="badgeCount"
                matBadgeSize="small"
                [matBadgeHidden]="badgeCount > 0 ? false : true"
                matBadgeOverlap="true"
                matBadgeColor="warn"
            >
                <i
                    class="fa-duotone fa-{{ icon }} fa-fw"
                    [ngClass]="{
                        'fa-2x': navLevel === 1,
                        'fa-md': navLevel === 2,
                        'fa-sm': navLevel >= 3,
                        'icon-navChild': navLevel >= 2
                    }"
                    aria-hidden="true"
                ></i>
            </div>

            <div
                class="label"
                [ngClass]="{ 'pl-md': navLevel === 1, 'pl-lg': navLevel === 2, 'pl-3rd-level': navLevel >= 3 }"
            >
                {{ label }}
            </div>

            <!-- Indikator für Sublinks und Schnellaktion -->
            <div class="indicator-content" *ngIf="children">
                <i *ngIf="!childrenVisible" class="fa-solid fa-chevron-right fa-fw fa-lg" aria-hidden="true"></i>
                <i *ngIf="childrenVisible" class="fa-solid fa-chevron-down fa-fw fa-lg" aria-hidden="true"></i>
            </div>
        </a>

        <!-- Zusätzliche Aktion -->
        <div
            class="action"
            matRipple
            *ngIf="actionEnabled && collapsed"
            [matTooltip]="actionTooltip"
            [ngClass]="{
                'display-1st-level': navLevel === 1,
                'display-2nd-level': navLevel === 2,
                'display-3rd-level': navLevel >= 3
            }"
            (click)="actionClicked($event)"
        >
            <i class="fa-duotone fa-{{ actionIcon }} fa-fw fa-lg" aria-hidden="true"></i>
        </div>
    </div>
</div>
