import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InitializationComponent } from '@core/components/initialization/initialization.component';
import { DialogActionsComponent, DialogContentComponent, DialogHeadingComponent } from '@k5next/ui-components';
import { SharedModule } from '@shared/shared.module';
import { MaterialModule } from '../material/material.module';
import { AlertComponent } from './components/alert/alert.component';
import { InfoWindowComponent } from './components/info-window/info-window.component';
import { NavContentComponent } from './components/nav-content/nav-content.component';
import { NavLinkComponent } from './components/nav-link/nav-link.component';
import { ProblemAlertComponent } from './components/problem-alert/problem-alert.component';
import { SimpleAlertComponent } from './components/simple-alert/simple-alert.component';
import { UnsavedChangesDialogComponent } from './components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { AuthGuard } from './guards/auth.guard';
import { throwIfAlreadyLoaded } from './guards/module-import.guard';
import { BildVorschauDialogComponent } from './components/bild-vorschau-dialog/bild-vorschau-dialog.component';

/**
 * Zentrales Modul für Grundlegende Services und Funktionen
 */
@NgModule({
    declarations: [
        AlertComponent,
        InitializationComponent,
        NavContentComponent,
        NavLinkComponent,
        InfoWindowComponent,
        SimpleAlertComponent,
        ProblemAlertComponent,
        UnsavedChangesDialogComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        ReactiveFormsModule,
        SharedModule,
        DialogHeadingComponent,
        DialogContentComponent,
        DialogActionsComponent,
        BildVorschauDialogComponent
    ],
    exports: [NavContentComponent, InitializationComponent, InfoWindowComponent],
    providers: [AuthGuard]
})
export class CoreModule {
    // prevents multiple imports of core module
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
