// @ts-strict-ignore
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UiConstants } from '@core/constants/ui-constants';
import { RouterLinkConstants } from '@core/constants/url-constants';
import { NavItem } from '@core/models/nav-item';
import { RoutingData } from '@core/models/routingData';
import { AlertService } from '@core/services/alert.service';
import { AuthService } from '@core/services/auth.service';
import { ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';
import { GlobalEditService } from '@core/services/global-edit.service';
import { MandantDarstellungService } from '@core/services/mandant-darstellung.service';
import { MandantenService } from '@core/services/mandanten.service';
import { NavigationService } from '@core/services/navigation.service';
import { UserInformationService } from '@core/services/user-information.service';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UnsavedChangesDialogComponent } from '../unsaved-changes-dialog/unsaved-changes-dialog.component';
import { Buffer } from 'buffer';

/**
 * Komponente für den Inhalt der Navigationsleiste
 */
@Component({
    selector: 'k5-nav-content',
    templateUrl: './nav-content.component.html',
    styleUrls: ['./nav-content.component.scss']
})
export class NavContentComponent implements OnInit, OnDestroy {
    private subscription: Subscription = new Subscription();

    navItemsStart$: Observable<NavItem[]>;
    navItemsCenter$: Observable<NavItem[]>;

    routerConstants = RouterLinkConstants;

    selectedNavItem?: NavItem = undefined;

    scrollTop = 0;

    /**
     * Konstruktor
     * @param authService AuthService
     * @param userService UserInformationService
     * @param navigationService NavigationService
     * @param router Router
     * @param notificationService NotificationService
     */
    constructor(
        private authService: AuthService,
        public userService: UserInformationService,
        public navigationService: NavigationService,
        private router: Router,
        private notificationService: NotificationService,
        public mandantDarstellungService: MandantDarstellungService,
        private globalEditService: GlobalEditService,
        private dialog: MatDialog,
        private userInformationService: UserInformationService,
        private title: Title,
        private configService: ConfigAssetLoaderService,
        private alertService: AlertService,
        private mandantenService: MandantenService
    ) {
        this.navItemsStart$ = this.navigationService.navItems$.pipe(
            map((navItems: NavItem[]) => navItems.filter((navItem: NavItem) => navItem.position === 'start'))
        );

        this.navItemsCenter$ = this.navigationService.navItems$.pipe(
            map((navItems: NavItem[]) => navItems.filter((navItem: NavItem) => navItem.position === 'center'))
        );
    }

    /**
     * OnInit erstellt das Menü mit Wappen und Menü-Einträgen
     */
    ngOnInit(): void {
        // Route subscription zur Änderung des Tab-Titels und
        this.subscription.add(
            this.router.events
                .pipe(
                    filter((event) => event instanceof NavigationEnd),
                    map((event: NavigationEnd): RoutingData => {
                        let route: ActivatedRoute = this.router.routerState.root;
                        let routeTitle = UiConstants.STANDARD_TITLE;
                        // Setzt route auf das unterste Child Element
                        while (route?.firstChild) {
                            route = route.firstChild;
                        }
                        // Liest Titel aus den Routing Daten aus
                        if (route?.snapshot?.data['title']) {
                            routeTitle =
                                route.snapshot.data['title'] + ' - ' + this.userInformationService.getMandantName();
                        }
                        const routingData: RoutingData = {
                            title: routeTitle,
                            urlAfterRedirects: event.urlAfterRedirects
                        };
                        return routingData;
                    })
                )
                .subscribe((routingData: RoutingData) => {
                    this.title.setTitle(routingData.title);
                    this.navigationService.handleUrlChange(routingData.urlAfterRedirects);
                })
        );
    }

    /**
     * Getter für Benachrichtigungsanzahl
     */
    get badgeCount(): number {
        return this.notificationService.notificationAmount;
    }

    /**
     * Getter für den Benutzernamen
     */
    get userName(): string {
        return this.userService.getUserName();
    }

    /**
     * Gibt das Profilbild zurück
     */
    getPicture(): string {
        const accessToken = this.authService.getAccessToken();
        let picture: string;
        if (accessToken) {
            const tokenData = accessToken.split('.')[1];
            const convertedToken = JSON.parse(Buffer.from(tokenData, 'base64').toString());
            picture = convertedToken.picture;
        }
        return picture;
    }

    /**
     * Überprüft ob der User angemeldet ist
     */
    isAuthenticated(): boolean {
        return this.authService.isAuthenticated();
    }

    /**
     * Prüft ob der angemeldete Benutzer das Recht besitzt den Menüeintrag zu sehen
     * @param permissions Zu prüfende Rechte
     */
    hasPermissions(permissions: string[]): boolean {
        return this.userService.hasPermissions(permissions);
    }

    /**
     * OnDestroy löst die Subscriptions auf
     */
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Behandelt scrolling des Menüs
     * @param event Scroll event
     */
    scrolled(event: Event): void {
        this.scrollTop = (event.target as HTMLElement).scrollTop;
    }

    /**
     * Logout
     */
    logout(): void {
        if (
            this.globalEditService.isEditing &&
            (this.globalEditService.getEditSectionForm() ? this.globalEditService.isFormDirty() : true)
        ) {
            const dialogRef = this.dialog.open(UnsavedChangesDialogComponent);
            dialogRef.componentInstance.leaveSiteObservable.subscribe({
                next: (leaveSite: boolean) => {
                    if (leaveSite) {
                        this.globalEditService.switchToReadMode();
                        this.authService.logout();
                    }
                }
            });
        } else if (this.isAuthenticated() && !this.globalEditService.isEditing) {
            this.authService.logout();
        } else {
            if (this.globalEditService.isEditing) {
                this.globalEditService.switchToReadMode();
            }
            this.authService.logout();
        }
    }

    /**
     * Wenn Vorschau aktiv ist, wird Wahltag und Verfahren auch dargestellt
     * @param navItem NavItem
     * @returns true oder false
     */
    isNavItemVisible(navItem: NavItem): boolean {
        if (navItem.label === UiConstants.WAHLTAG_NAME || navItem.label === UiConstants.VERFAHREN_NAME) {
            return this.userInformationService.getVorschauAktiv();
        }
        return true;
    }

    /**
     * Prüft, ob die Form bereits verändert wurde
     */
    checkUnsavedChanges(): void {
        if (!this.globalEditService.isFormDirty() && this.globalEditService.getEditSectionForm()) {
            this.globalEditService.switchToReadMode();
        }
    }

    /**
     * Öffnet die K5|Next Hilfe Seite in einem neuen Tab, wenn auf die Hilfe Menü geklickt wurde
     */
    hilfeNavLinkClicked(): void {
        // k5|Next Hilfe Access Token abfragen
        this.mandantenService.getK5NextHilfeToken().subscribe({
            next: (token: string) => {
                // Die Seite öffnen
                window.open(`${this.configService.getConfig().k5NextHilfe.baseUrl}/?t=${token}`);
            },
            error: (error: HttpErrorResponse) => {
                this.alertService.errorResponse(error, 'Fehler bei der Abfrage des K5Next Hilfe Access Tokens');
            }
        });
    }
}
